import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";

const state = () => {
  return {
    refreshingCalculations: false
  };
};

// getters
const getters = {
  refreshingCalculations: state => state.refreshingCalculations
};

// actions
const actions = {
  refreshHemCalculations: async ({ dispatch, commit }, payload) => {
    try {
      commit("setRefreshingCalculations");
      axiosWrapperService
        .axiosPost(
          "/gateway-proxies/fundo-core/hem-calculations/refresh",
          payload
        )
        .then(response => {
          dispatch(
            "globalToastMessage/showSimpleToast",
            { message: "Hem calculations refreshed!", type: "success" },
            { root: true }
          );
          dispatch(
            "loanAssessmentInfos/getAggregatorPersonalLoansForLoanAssessment",
            {
              showLoader: true
            },
            { root: true }
          );
          dispatch(
            "loanAssessmentInfos/getAggregatorIncomeExpensesDetailsForLoanAssessment",
            {
              showLoader: true
            },
            { root: true }
          );
          dispatch(
            "loanAssessmentInfos/getAggregatorLendingDetailsForLoanAssessment",
            {
              showLoader: true
            },
            { root: true }
          );
          commit("setRefreshingCalculations");
        })
        .catch(error => {
          dispatch(
            "globalToastMessage/showSimpleToast",
            {
              message: formatErrorMessage(error),
              type: "error"
            },
            { root: true }
          );
          commit("resetRefreshingCalculations");
        })
        .finally(() => commit("resetRefreshingCalculations"));
    } catch (e) {
      dispatch(
        "globalToastMessage/showSimpleToast",
        {
          message: formatErrorMessage(e),
          type: "error"
        },
        { root: true }
      );
      commit("resetRefreshingCalculations");
    }
  }
};

// mutations
const mutations = {
  setRefreshingCalculations(state) {
    state.refreshingCalculations = true;
  },
  resetRefreshingCalculations(state) {
    state.refreshingCalculations = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
