<template>
  <!-- <div class="grid grid-cols-2 gap-6 mt-6"> -->
  <div class="grid grid-cols-12 gap-6">
    <div v-if="!isBusy" class="col-span-12">
      <div class="box">
        <div class="p-5">
          <div class="grid grid-cols-12 gap-x-5">
            <div class="col-span-12 p-5">
              <h4 class="font-medium text-theme-3 text-base mb-5">
                Credit Check
              </h4>

              <div v-if="creditCheckData">
                <div class="grid grid-cols-12">
                  <label class="form-label col-span-5 mt-1.5">
                    <AwardIcon class="w-4 h-4 mr-1" />
                    Score:</label
                  >
                  <div
                    class="col-span-7 text-center md:text-left text-primary-11 mt-1.5"
                  >
                    {{ creditCheckData.risk_score }}
                    <button
                      v-if="regenerateCreditCheck"
                      class="btn bg-theme-3 text-white float-right"
                      title="Regenerate"
                      @click="regenerateCreditCheckReport"
                    >
                      <RefreshCcwIcon class="w-4 h-4 mr-2" />
                      Regenerate
                    </button>
                  </div>
                </div>
                <div class="mt-3 grid grid-cols-12">
                  <label class="form-label col-span-5 mt-1.5">
                    <CalendarIcon class="w-4 h-4 mr-1" />
                    Score Date:</label
                  >
                  <div
                    class="col-span-6 text-center md:text-left  text-primary-11 mt-1.5"
                  >
                    {{ creditCheckData.risk_score_date }}
                  </div>
                </div>

                <div
                  v-if="creditCheckData.requested_by"
                  class="mt-3 grid grid-cols-12"
                >
                  <label class="form-label col-span-5 mt-1.5">
                    <CalendarIcon class="w-4 h-4 mr-1" />
                    Requested By:</label
                  >
                  <div
                    class="col-span-6 text-center md:text-left  text-primary-11 mt-1.5"
                  >
                    {{ creditCheckData.requested_by?.fullname }}
                  </div>
                </div>
                <button
                  class="btn bg-theme-3 w-48 mt-2 text-white"
                  @click="showFullCreditReport"
                >
                  View Full Report
                </button>
              </div>
              <button
                v-else
                class="btn bg-theme-3 w-60 text-white"
                @click="generateCreditCheck"
              >
                <CreditCardIcon class="w-4 h-4 mr-2" />
                Generate Credit Check
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FetchingRecordDivTemplate v-else marginTop="0" />
  </div>

  <!-- </div> -->

  <FullReportModal />
</template>

<script>
import { useStore } from "@/store";
import { computed, watch, ref } from "vue";
import FullReportModal from "@/components/loan-application-details/credit-check/FullReportModal";
import FetchingRecordDivTemplate from "@/components/fetching-record-div-template/Main";

export default {
  components: {
    FullReportModal,
    FetchingRecordDivTemplate
  },
  props: {
    customerId: {
      type: Number,
      require: true
    }
  },
  setup(props) {
    const store = useStore();

    let regenerateCreditCheck = ref(false);

    const creditCheckData = computed(
      () => store.getters["creditCheck/creditCheck"]
    );
    const isBusy = computed(() => store.getters["creditCheck/isBusy"]);

    const loan = computed(() => store.state.customerAndLoanDetails.loan);

    watch(
      () => loan.value,
      application =>
        (regenerateCreditCheck.value = ["Applied", "Approved"].includes(
          application.status
        ))
    );

    function generateCreditCheck() {
      store.dispatch("creditCheck/generateCreditCheck");
    }

    function regenerateCreditCheckReport() {
      generateCreditCheck();
    }

    function showFullCreditReport() {
      cash("#credit-check-report-modal").modal("show");
    }

    init();

    function init() {
      store.commit("creditCheck/setCustomerId", props.customerId);
      store.dispatch("creditCheck/getCreditCheck");
    }

    return {
      generateCreditCheck,
      creditCheckData,
      showFullCreditReport,
      isBusy,
      regenerateCreditCheck,
      regenerateCreditCheckReport
    };
  }
};
</script>

<style scoped></style>
