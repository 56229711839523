<template>
  <div>
    <div
      v-if="applicationId < 1"
      class="bg-theme-16 cursor-pointer shadow-md fixed bottom-0 left-80 box shadow-md border rounded-full w-40 h-12 flex items-center justify-center z-50 mb-10 mr-56"
    >
      <LoadingIcon class="w-8 h-8 ml-2" color="green" icon="three-dots" />
    </div>
    <div
      v-else
      class="bg-theme-16 cursor-pointer shadow-md fixed bottom-0 left-80 box shadow-md border rounded-full w-40 h-12 flex items-center justify-center z-50 mb-10 mr-56"
      @click="showAddLoanFollowUpModal"
    >
      <div class="ml-1">
        <PhoneForwardedIcon class="w-4 h-4 side-menu__icon text-white" />
        <span class="ml-2 text-white">Add Follow Up</span>
      </div>
      <!-- BEGIN: Modal Content -->
      <div
        id="add-new-followup-modal"
        aria-hidden="true"
        class="modal"
        tabindex="-1"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <!-- BEGIN: Modal Header -->
            <div class="modal-header">
              <h2 class="font-medium text-base mr-auto">
                Add Follow Up
              </h2>
            </div>
            <div
              v-if="newGenericFollowUpValidationMessage !== ''"
              class="alert alert-danger-soft show flex items-center mb-2"
              role="alert"
            >
              <AlertOctagonIcon class="w-6 h-6 mr-2" />
              {{ newGenericFollowUpValidationMessage }}
              <button
                class="btn-close"
                type="button"
                @click="clearAddGenericFollowUpFormMessages"
              >
                <XIcon class="w-4 h-4" />
              </button>
            </div>
            <!-- END: Modal Header -->
            <!-- BEGIN: Modal Body -->
            <div class="modal-body grid grid-cols-12">
              <div class="col-span-12 mt-2">
                <div
                  class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
                >
                  <div class="sm:flex items-center sm:mr-2">
                    <label class="w-full flex-none xl:flex-initial mr-2"
                      >Followup Type</label
                    >
                    <select
                      id="tabulator-html-filter-field"
                      v-model="selectedFollowUpType"
                      class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
                      @change="onChangeSelectedFollowUpType($event)"
                    >
                      <option value="">None</option>
                      <option value="docs_req">Doc Request</option>
                      <option value="card_verf">Card Verification</option>
                      <option value="bank_reauth">Bank Reauth</option>
                      <option value="bank_statements_unavailable"
                        >Bank Statement Unavailable</option
                      >
                    </select>
                  </div>
                </div>
                <label
                  v-if="selectedFollowUpType === 'docs_req'"
                  class="form-label mt-4"
                >
                  Does Not Expire Until Withdrawn:</label
                >
                <div v-if="selectedFollowUpType === 'docs_req'" class="flex">
                  <h4
                    :class="[
                      newGenericFollowUpFormObject.until_withdrawn
                        ? 'text-theme-3'
                        : 'text-theme-6'
                    ]"
                    class="
                        col-span-5
                        font-medium
                        text-base
                        mt-5
                        mb-5
                      "
                  >
                    {{
                      newGenericFollowUpFormObject.until_withdrawn
                        ? "Yes"
                        : "No"
                    }}
                  </h4>
                  <div class="flex items-center ml-2">
                    <div
                      class="dark-mode-switcher cursor-pointer"
                      @click="toggleUntilWithdrawnAndResetExpiry()"
                    >
                      <div
                        :class="{
                          'dark-mode-switcher__toggle--active':
                            newGenericFollowUpFormObject.until_withdrawn
                        }"
                        class="dark-mode-switcher__toggle border"
                      ></div>
                    </div>
                  </div>
                </div>
                <label
                  v-if="
                    selectedFollowUpType === 'docs_req' &&
                      !newGenericFollowUpFormObject.until_withdrawn
                  "
                  class="form-label mt-4"
                >
                  Select Expiry Date for Document Request Follow Up:</label
                >
                <input
                  v-if="
                    selectedFollowUpType === 'docs_req' &&
                      !newGenericFollowUpFormObject.until_withdrawn
                  "
                  v-model="newGenericFollowUpFormObject.expiry"
                  :min="new Date().toISOString().slice(0, 10)"
                  class="form-control w-4/5"
                  type="date"
                />
                <label
                  v-if="selectedFollowUpType === 'card_verf'"
                  class="form-label mt-4"
                >
                  Last 4 digit Of Card:</label
                >
                <label v-else class="form-label mt-4 w-full">
                  Description:</label
                >
                <input
                  v-if="selectedFollowUpType === 'card_verf'"
                  v-model="newGenericFollowUpFormObject.description"
                  class="form-control w-1/3 mt-4 ml-2"
                  maxlength="4"
                  type="text"
                  @keyup="
                    e =>
                      (newGenericFollowUpFormObject.description = newGenericFollowUpFormObject.description.replace(
                        /[^x0-9]/g,
                        ''
                      ))
                  "
                />
                <textarea
                  v-else
                  id="modal-form-1"
                  v-model="newGenericFollowUpFormObject.description"
                  class="form-control mt-4"
                  rows="7"
                  type="text"
                >
                </textarea>
              </div>
            </div>
            <!-- END: Modal Body -->
            <!-- BEGIN: Modal Footer -->
            <div class="modal-footer text-right">
              <button
                v-if="!processingSubmit"
                class="btn btn-dark-soft w-20 mr-1"
                data-dismiss="modal"
                type="button"
              >
                Cancel
              </button>
              <button
                v-if="processingSubmit"
                class="btn btn-success w-40"
                type="button"
              >
                <LoadingIcon class="w-8 h-8 ml-2" color="green" icon="oval" />
              </button>
              <button
                v-else
                class="btn btn-success w-40"
                type="button"
                @click="onSubmitAddNewLoanFollowUps"
              >
                Submit
              </button>
            </div>
            <!-- END: Modal Footer -->
          </div>
        </div>
      </div>
      <!-- END: Modal Content -->
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { useStore } from "@/store";
import cash from "cash-dom/dist/cash";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";
import { customSwalMessageBuilder } from "@/composables/utils/customSwalMessageBuilder";

export default defineComponent({
  props: {
    applicationId: {
      type: Number,
      require: false
    }
  },
  setup(props) {
    const store = useStore();

    const { buildSwalWarning, buildSwalError } = customSwalMessageBuilder();

    const processingSubmit = computed(
      () => store.state.addFollowUp.processingSubmit
    );

    const adminUser = computed(() => store.state.auth.loggedUser);

    const loan = computed(() => store.state.customerAndLoanDetails.loan);

    const selectedFollowUpType = ref("");

    const route = useRoute();
    const path = computed(() => route.path);
    const assessorPageType = ref(path.value.split("/")[2]);

    function onToggleQuickNavBody() {
      store.commit("quickNavLinks/toggleIsQuickNavExpanded");
    }

    const newGenericFollowUpFormObject = reactive({
      until_withdrawn: true,
      description: "",
      expiry: ""
    });

    function toggleUntilWithdrawnAndResetExpiry() {
      newGenericFollowUpFormObject.until_withdrawn = !newGenericFollowUpFormObject.until_withdrawn;
      newGenericFollowUpFormObject.expiry = "";
    }

    function onChangeSelectedFollowUpType(event) {
      resetNewGenericFollowUpValidationMessage();
      resetNewGenericFollowUpObject();
    }

    function validateNewGenericFollowUpForm() {
      resetNewGenericFollowUpValidationMessage();
      if (
        selectedFollowUpType.value === "docs_req" &&
        newGenericFollowUpFormObject.expiry === ""
      ) {
        if (
          !newGenericFollowUpFormObject.until_withdrawn &&
          newGenericFollowUpFormObject.expiry === ""
        ) {
          newGenericFollowUpValidationMessage.value +=
            "Either Follow Up should be marked to expire until withdrawn or Expiry Date should be provided when Follow Up type is Document Request.";
        }
      }
      if (newGenericFollowUpFormObject.description === "") {
        newGenericFollowUpValidationMessage.value +=
          "Follow Up Description cannot be empty !";
      }
      if (newGenericFollowUpValidationMessage.value !== "") {
        return false;
      }
      return true;
    }

    function showCompleteOtherFollowupsAlert(followUpTypes) {
      Swal.fire(
        buildSwalWarning(
          `We have detected follow up types: ${followUpTypes.join(
            ", "
          )}. Creating a new follow-up will cancel this, are you sure you want to continue?`,
          true,
          "Warning"
        )
      ).then(confirmed => {
        if (confirmed.isConfirmed) {
          addFollowUp();
        }
      });
    }

    function checkIfOtherExistingFollowUps() {
      store.dispatch("addFollowUp/getNonCompleteFollowUpsForApplication", {
        applicationId: props.applicationId
      });
    }

    const existingFollowUps = computed(
      () => store.getters["addFollowUp/existingFollowUps"]
    );

    watch(
      () => existingFollowUps.value,
      followups => {
        cash("#add-new-followup-modal").modal("hide");
        if (followups.length) {
          const followUpTypes = followups.map(x => x.follow_up_type_slug);
          showCompleteOtherFollowupsAlert(followUpTypes);
        } else {
          addFollowUp();
        }
      }
    );

    function addFollowUp() {
      store.commit("main/setFullPageLoading");
      if (selectedFollowUpType.value === "docs_req") {
        store.dispatch("addFollowUp/addGenericDocReqFollowUpOnLoan", {
          applicationId: props.applicationId,
          formData: newGenericFollowUpFormObject
        });
      } else if (selectedFollowUpType.value === "card_verf") {
        //Only allowed to add if application is approved
        if (["Approved"].indexOf(loan.value.status) === -1) {
          Swal.fire(
            buildSwalError(
              `Card verification follow up can only be created on approved application!`
            )
          );
        } else {
          store.dispatch("addFollowUp/addCardVerfFollowUpOnLoan", {
            applicationId: props.applicationId,
            formData: newGenericFollowUpFormObject
          });
        }
      } else if (selectedFollowUpType.value === "bank_reauth") {
        store.dispatch("addFollowUp/addBankReauthFollowUpOnLoan", {
          applicationId: props.applicationId,
          formData: newGenericFollowUpFormObject
        });
      } else if (selectedFollowUpType.value === "bank_statements_unavailable") {
        store.dispatch(
          "addFollowUp/addBankStatementsUnavailableFollowUpOnLoan",
          {
            applicationId: props.applicationId,
            formData: newGenericFollowUpFormObject
          }
        );
      }
    }

    function onSubmitAddNewLoanFollowUps() {
      if (validateNewGenericFollowUpForm()) {
        clearAddGenericFollowUpFormMessages();
        checkIfOtherExistingFollowUps();
      }
    }

    function resetNewGenericFollowUpObject() {
      newGenericFollowUpFormObject.description = "";
      newGenericFollowUpFormObject.expiry = "";
      newGenericFollowUpFormObject.until_withdrawn = true;
    }

    function resetSelectedFollowUpType() {
      selectedFollowUpType.value = "";
    }

    const newGenericFollowUpValidationMessage = ref("");

    function resetNewGenericFollowUpValidationMessage() {
      newGenericFollowUpValidationMessage.value = "";
    }

    function showAddLoanFollowUpModal() {
      resetNewGenericFollowUpObject();
      resetNewGenericFollowUpValidationMessage();
      resetSelectedFollowUpType();
      cash("#add-new-followup-modal").modal("show");
    }

    function clearAddGenericFollowUpFormMessages() {
      resetNewGenericFollowUpValidationMessage();
    }

    return {
      adminUser,
      selectedFollowUpType,
      assessorPageType,
      onChangeSelectedFollowUpType,
      processingSubmit,
      onToggleQuickNavBody,
      newGenericFollowUpValidationMessage,
      newGenericFollowUpFormObject,
      toggleUntilWithdrawnAndResetExpiry,
      clearAddGenericFollowUpFormMessages,
      showAddLoanFollowUpModal,
      onSubmitAddNewLoanFollowUps
    };
  }
});
</script>
