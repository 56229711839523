<template>
  <div>
    <!-- BEGIN: Customer and Loan Details -->
    <div v-if="showPersonalDetails">
      <div
        :class="[
          { 'bg-theme-32': isActive },
          `loan-${getActiveApplicationQualityClass()}`
        ]"
        class="mt-1 p-4 box cursor-pointer section-header"
        @click="setNavActiveSection('kyc-title')"
      >
        <div class="flex justify-between">
          <div class="flex flex-row">
            <div class="">
              <h2
                id="kyc-title"
                class="font-medium text-theme-7 text-base mr-5"
              >
                KNOW YOUR CUSTOMER(KYC)
              </h2>
            </div>
            <div v-if="!isActive" class="flex flex-row">
              <div
                v-for="(s, index) in summary"
                :key="index"
                class="mr-1 border-l pr-2 pl-2"
                style="font-size: smaller"
              >
                <span class="font-bold">{{ s.name }}:</span>
                <span class="ml-2">{{ s.value }}</span>
              </div>
            </div>
          </div>
          <div class="">
            <ChevronDownIcon :class="{ 'transform rotate-180': !isActive }" />
          </div>
        </div>
      </div>
      <div v-if="isActive" id="kyc-dts-section" class="grid grid-cols-12 gap-6">
        <div v-if="!fetchingKYC" class="col-span-12">
          <div class="box">
            <div class="p-5">
              <div class="grid grid-cols-12 gap-2 x-5">
                <div class="col-span-12 xl:col-span-6 p-5">
                  <div class="flex">
                    <h4 class="font-medium text-theme-3 text-base mb-5">
                      Personal Details
                    </h4>
                    <div class="grid grid-cols-2 gap mb-5">
                      <span
                        v-if="fetchingCustomerMetaFlags"
                        class="flex justify-center items-center text-xs px-1 rounded-full border bg-black bg-opacity-50 text-theme-2 dark:border-darkmode-100/40 dark:text-slate-300 mr-1"
                      >
                        Fetching Customer Refund status ...
                      </span>
                      <span
                        v-else-if="
                          !fetchingCustomerMetaFlags &&
                            customerMetaFlags.has_refund_req
                        "
                        class="flex justify-center items-center text-xs px-1 refund-label rounded-full border bg-black bg-opacity-50 text-theme-2 dark:border-darkmode-100/40 dark:text-slate-300 mr-1"
                      >
                        Refund Request Pending
                      </span>

                      <span
                        v-if="loan?.assessment_disable_operation"
                        class="flex justify-center items-center text-xs px-1 refund-label rounded-full border bg-black bg-opacity-50 text-theme-2 dark:border-darkmode-100/40 dark:text-slate-300 mr-1"
                        >Marked for decline
                      </span>
                    </div>
                  </div>
                  <div class="grid grid-cols-12">
                    <label
                      class="form-label col-span-3 mt-1.5"
                      for="ln-assess-cust-name"
                    >
                      <UserIcon class="w-4 h-4 mr-2" />
                      Customer Name
                    </label>
                    <span
                      id="ln-assess-cust-name"
                      class="form-control col-span-6 gap-3"
                    >
                      {{ kycWithAdditionalAttributes.basicAttributes.fullname }}
                      <span class="text-blue-700 text-sm underline float-right">
                        <a
                          id="ln-assess-cust-name"
                          :href="
                            `/customers/customer/${kycWithAdditionalAttributes?.basicAttributes.user_id}`
                          "
                          class="col-span-6 gap-3 cursor-pointer"
                          target="_blank"
                          type="text"
                        >
                          <ExternalLinkIcon
                            class="w-4 h-4 mr-1 align-text-top"
                          />
                          Profile
                        </a></span
                      >
                    </span>
                  </div>

                  <div class="mt-3 grid grid-cols-12">
                    <label
                      class="form-label col-span-3 mt-1.5"
                      for="ln-assess-email"
                    >
                      <MailIcon class="w-4 h-4 mr-2" />
                      Email
                    </label>
                    <span
                      id="ln-assess-cust-email"
                      class="form-control col-span-6 gap-3 break-words"
                      type="text"
                    >
                      <template v-if="customerDeleted">
                        N/A
                      </template>
                      <template v-else>
                        {{ kycWithAdditionalAttributes.basicAttributes.email }}
                        <span
                          class="text-blue-700 text-sm underline float-right"
                        >
                          <a
                            id="ln-assess-cust-email"
                            class="col-span-6 gap-3 cursor-pointer"
                            target="_blank"
                            @click="openCommsHistoryLink()"
                          >
                            <ExternalLinkIcon
                              class="w-4 h-4 mr-1 align-text-top"
                            />
                            Comms
                          </a></span
                        >
                      </template>
                    </span>
                  </div>
                  <div class="mt-3 grid grid-cols-12">
                    <label
                      class="form-label col-span-3 mt-1.5"
                      for="ln-assess-phone"
                    >
                      <PhoneCallIcon class="w-4 h-4 mr-2" />
                      Phone</label
                    >
                    <input
                      id="ln-assess-phone"
                      :value="
                        customerDeleted
                          ? 'N/A'
                          : kycWithAdditionalAttributes.basicAttributes.mobile
                      "
                      class="form-control col-span-6 gap-3"
                      disabled
                      placeholder="Input text"
                      type="text"
                    />
                  </div>
                  <div class="mt-3 grid grid-cols-12">
                    <label
                      class="form-label col-span-3 mt-1.5"
                      for="ln-assess-address"
                    >
                      <MapPinIcon class="w-4 h-4 mr-2" />
                      Address</label
                    >
                    <input
                      id="ln-assess-address"
                      :value="
                        kycWithAdditionalAttributes.derivedAttributes.address
                      "
                      class="form-control col-span-6 gap-3"
                      disabled
                      placeholder="Input text"
                      type="text"
                    />
                  </div>
                  <div class="mt-3 grid grid-cols-12">
                    <label
                      class="form-label col-span-3 mt-1.5"
                      for="ln-assess-dob"
                    >
                      <CalendarIcon class="w-4 h-4 mr-2" />
                      Date Of Birth</label
                    >
                    <input
                      id="ln-assess-dob"
                      :value="kycWithAdditionalAttributes.basicAttributes.dob"
                      class="form-control col-span-6 gap-3"
                      disabled
                      placeholder="Input text"
                      type="text"
                    />
                  </div>
                  <div
                    v-if="
                      kycWithAdditionalAttributes.basicAttributes
                        .blacklisting_reason.length
                    "
                    class="mt-3 grid grid-cols-12"
                  >
                    <label
                      class="form-label col-span-3 mt-1.5"
                      for="ln-assess-dob"
                    >
                      <CalendarIcon class="w-4 h-4 mr-2" />
                      Reason to Blacklist</label
                    >
                    <input
                      id="ln-assess-blacklist-reason"
                      :value="
                        kycWithAdditionalAttributes.basicAttributes
                          .blacklisting_reason
                      "
                      class="form-control col-span-6 gap-3"
                      disabled
                      placeholder="Input text"
                      type="text"
                    />
                  </div>
                  <div class="mt-3 grid grid-cols-3">
                    <Anonymize
                      v-if="userRoles.includes('customer-anonymizer')"
                      :customer-id="
                        kycWithAdditionalAttributes?.basicAttributes.user_id
                      "
                      :full-name="
                        kycWithAdditionalAttributes.basicAttributes.fullname
                      "
                    />
                  </div>
                </div>

                <div class="col-span-12 xl:col-span-6 p-5">
                  <h4 class="font-medium text-theme-3 text-base mb-5">
                    Living Situation
                  </h4>
                  <div class="grid grid-cols-12">
                    <label
                      class="form-label col-span-3 mt-1.5"
                      for="ln-assess-aust-citizen"
                      >Australian Citizen</label
                    >
                    <input
                      id="ln-assess-aust-citizen"
                      :value="getKycAttributeValueByName('Australian_Citizen')"
                      class="form-control col-span-6 gap-3"
                      disabled
                      placeholder="Input text"
                      type="text"
                    />
                  </div>
                  <div class="mt-3 grid grid-cols-12">
                    <label
                      class="form-label col-span-3 mt-1.5"
                      for="ln-assess-dependents"
                      >Dependents</label
                    >
                    <input
                      id="ln-assess-dependents"
                      :value="getKycAttributeValueByName('Dependent')"
                      class="form-control col-span-6 gap-3"
                      disabled
                      placeholder="Input text"
                      type="text"
                      @change="
                        e => updateAttributes('Dependent', e.target.value)
                      "
                    />
                  </div>
                  <div class="mt-3 grid grid-cols-12">
                    <label
                      class="form-label col-span-3 mt-1.5"
                      for="ln-assess-relationship"
                      >Relationships</label
                    >
                    <select
                      id="ln-assess-relationship"
                      v-model="singleAsRelationShip"
                      class="form-control col-span-6 gap-3"
                      disabled
                      @change="
                        e =>
                          updateAttributes(
                            'partner_who_shares_expenses',
                            e.target.value
                          )
                      "
                    >
                      <option value="no">Single</option>
                      <option value="yes">Couple</option>
                    </select>
                  </div>

                  <div class="grid grid-cols-2 gap-1">
                    <div>
                      <div class="flex">
                        <h4
                          class="
                        col-span-5
                        font-medium
                        text-theme-3 text-base
                        mt-5
                        mb-5
                      "
                        >
                          On Assessment Priority
                        </h4>
                        <div class="flex items-center ml-2">
                          <div
                            v-show="!assessmentPriorityLoading"
                            :style="
                              assessmentPriorityLoading
                                ? { 'pointer-events': 'none' }
                                : {}
                            "
                            class="dark-mode-switcher"
                          >
                            <div
                              :class="{
                                'dark-mode-switcher__toggle--active':
                                  kycWithAdditionalAttributes.basicAttributes
                                    .on_assessment_priority
                              }"
                              class="dark-mode-switcher__toggle border"
                              @click="
                                e => {
                                  toggleOnAssessmentPriority();
                                  e.target.classList.toggle(
                                    'dark-mode-switcher__toggle--active'
                                  );
                                }
                              "
                            ></div>
                          </div>
                          <div v-if="assessmentPriorityLoading">
                            <LoadingIcon color="green" icon="tail-spin" />
                          </div>
                        </div>
                      </div>
                      <div class="flex">
                        <h4
                          class="
                        col-span-5
                        font-medium
                        text-theme-3 text-base
                        mt-5
                        mb-5
                      "
                        >
                          Do Not Market
                        </h4>
                        <div class="flex items-center ml-2">
                          <div
                            v-show="!doNotMarketLoading"
                            :style="
                              doNotMarketLoading
                                ? { 'pointer-events': 'none' }
                                : {}
                            "
                            class="dark-mode-switcher"
                          >
                            <div
                              :class="{
                                'dark-mode-switcher__toggle--active':
                                  kycWithAdditionalAttributes.basicAttributes
                                    .do_not_market
                              }"
                              class="dark-mode-switcher__toggle border"
                              @click="
                                e => {
                                  toggleDoNotMarket();
                                  e.target.classList.toggle(
                                    'dark-mode-switcher__toggle--active'
                                  );
                                }
                              "
                            ></div>
                          </div>
                          <div v-if="doNotMarketLoading">
                            <LoadingIcon color="green" icon="tail-spin" />
                          </div>
                        </div>
                      </div>
                      <div class="flex">
                        <h4
                          class="
                        col-span-5
                        font-medium
                        text-theme-3 text-base
                        mt-5
                        mb-5
                      "
                        >
                          Disable Lisa Calls
                        </h4>
                        <div class="flex items-center ml-2">
                          <div
                            v-show="!disableLisaCallsLoading"
                            :style="
                              disableLisaCallsLoading
                                ? { 'pointer-events': 'none' }
                                : {}
                            "
                            class="dark-mode-switcher"
                          >
                            <div
                              :class="{
                                'dark-mode-switcher__toggle--active':
                                  kycWithAdditionalAttributes.basicAttributes
                                    .disable_lisa_calls
                              }"
                              class="dark-mode-switcher__toggle border"
                              @click="
                                e => {
                                  toggleDisableLisaCalls();
                                  e.target.classList.toggle(
                                    'dark-mode-switcher__toggle--active'
                                  );
                                }
                              "
                            ></div>
                          </div>
                          <div v-if="disableLisaCallsLoading">
                            <LoadingIcon color="green" icon="tail-spin" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--                    <div class="flex flex-col justify-center">-->
                    <!--                      <RefreshHem :application-id="applicationId" />-->
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FetchingRecordDivTemplate v-else marginTop="0" />
      </div>
    </div>
  </div>
</template>

<script>
import { customerAndLoanDetails } from "@/composables/components/customerAndLoanDetails";
import { customerOperation } from "@/composables/customer";
import { computed, defineComponent, watch, ref } from "vue";
import { useStore } from "@/store";
import FetchingRecordDivTemplate from "@/components/fetching-record-div-template/Main";
import Anonymize from "@/components/Customer/Anonymize";
import { helper } from "@/utils/helper";
import { loanQuality } from "@/composables/loanQuality";
// import RefreshHem from "@/components/hem/Main";

export default defineComponent({
  components: {
    FetchingRecordDivTemplate,
    Anonymize
  },
  props: {
    applicationId: {
      type: Number,
      require: true,
      default: null
    },
    showPersonalDetails: {
      type: Boolean,
      require: false,
      default: true
    },
    showLivingSituation: {
      type: Boolean,
      require: false,
      default: true
    },
    showLoanInfo: {
      type: Boolean,
      require: false,
      default: true
    },
    showDiscretionaryInfo: {
      type: Boolean,
      require: false,
      default: true
    },
    showAccommodationInfo: {
      type: Boolean,
      require: false,
      default: true
    },
    setNavActiveSection: {
      type: Function,
      require: true,
      default: () => ({})
    },
    isActive: {
      type: Boolean,
      require: true,
      default: false
    }
  },
  setup(props) {
    const store = useStore();

    const {
      fetchingCustomerMetaFlags,
      customerMetaFlags,
      fetchingKYC,
      kycWithAdditionalAttributes,
      getKycAttributeValueByName,
      fetchingLoan,
      loan,
      toggleOnAssessmentPriority,
      toggleDoNotMarket,
      toggleDisableLisaCalls,
      onClickTransformToInput,
      updateAttributes
    } = customerAndLoanDetails(props);

    const openCommsHistoryLink = () => {
      const url = `${process.env.VUE_APP_COMMS_APP_URL}/customer/${kycWithAdditionalAttributes.value?.basicAttributes.user_id}`;
      helper.openUrl(url);
    };

    const assessmentPriorityLoading = computed(
      () => store.state.customerAndLoanDetails.assessmentPriorityLoading
    );

    const doNotMarketLoading = computed(
      () => store.state.customerAndLoanDetails.doNotMarketLoading
    );

    const disableLisaCallsLoading = computed(
      () => store.state.customerAndLoanDetails.disableLisaCallsLoading
    );

    const singleAsRelationShip = ref("");

    const relationship = computed(() =>
      getKycAttributeValueByName("partner_who_shares_expenses").toLowerCase()
    );

    watch(
      () => relationship.value,
      val => (singleAsRelationShip.value = val)
    );

    const summary = computed(() => {
      return [
        {
          name: "Name",
          value: kycWithAdditionalAttributes.value?.basicAttributes.fullname
        },
        {
          name: "Australian Citizen",
          value: getKycAttributeValueByName("Australian_Citizen")
        }
      ];
    });

    const { customerDeleted, userRoles } = customerOperation();
    const { getActiveApplicationQualityClass } = loanQuality();

    return {
      openCommsHistoryLink,
      toggleOnAssessmentPriority,
      toggleDoNotMarket,
      toggleDisableLisaCalls,
      fetchingCustomerMetaFlags,
      customerMetaFlags,
      fetchingKYC,
      fetchingLoan,
      kycWithAdditionalAttributes,
      getKycAttributeValueByName,
      loan,
      assessmentPriorityLoading,
      doNotMarketLoading,
      disableLisaCallsLoading,
      onClickTransformToInput,
      summary,
      customerDeleted,
      userRoles,
      getActiveApplicationQualityClass,
      updateAttributes,
      singleAsRelationShip
    };
  }
});
</script>

<style scoped>
.refund-label {
  background: #ff673b;
}
</style>
