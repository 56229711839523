import { axiosWrapperService } from "@/services/auth/axios-wrapper-service";
import { formatErrorMessage } from "@/utils/messageFormatter";
import { buildCustomerAddressAttribute } from "@/utils/customerAttributesBuilder";

const state = () => {
  return {
    applicationId: 0,
    fetchingCustomerMetaFlags: true,
    customerMetaFlags: {
      is_blacklisted: false,
      has_refund_req: false
    },
    fetchingKYC: true,
    kycWithAdditionalAttributes: {
      basicAttributes: {},
      derivedAttributes: {}
    },
    fetchingLoan: true,
    loan: {},
    loanMetaAttributesForAssessment: {},
    assessmentPriorityLoading: false,
    doNotMarketLoading: false,
    disableLisaCallsLoading: false,
    blackListed: false
  };
};

const getters = {
  fetchingCustomerMetaFlags: state => state.fetchingCustomerMetaFlags,
  customerMetaFlags: state => state.customerMetaFlags,
  fetchingKYC: state => state.fetchingKYC,
  kycWithAdditionalAttributes: state => state.kycWithAdditionalAttributes,
  loan: state => state.loan,
  loanMetaAttributesForAssessment: state =>
    state.loanMetaAttributesForAssessment,
  fetchingLoan: state => state.fetchingLoan,
  blackListed: state => state.blackListed
};

const mutations = {
  setFetchingCustomerMetaFlags(state) {
    state.fetchingCustomerMetaFlags = true;
  },
  resetFetchingCustomerMetaFlags(state) {
    state.fetchingCustomerMetaFlags = false;
  },
  setCustomerMetaFlags(state, customerMetaFlags) {
    state.customerMetaFlags.is_blacklisted = customerMetaFlags.is_blacklisted;
    state.customerMetaFlags.has_refund_req = customerMetaFlags.has_refund_req;
  },
  resetCustomerMetaFlags(state) {
    state.customerMetaFlags.is_blacklisted = false;
    state.customerMetaFlags.has_refund_req = false;
  },
  setFetchingKYC(state) {
    state.fetchingKYC = true;
  },
  resetFetchingKYC(state) {
    state.fetchingKYC = false;
  },
  setApplicationId(state, applicationId) {
    state.applicationId = applicationId;
  },
  clearAndPushToKycWithAdditionalAttributes(
    state,
    kycWithAdditionalAttributes
  ) {
    state.kycWithAdditionalAttributes.basicAttributes = kycWithAdditionalAttributes;
    state.kycWithAdditionalAttributes.derivedAttributes = buildCustomerAddressAttribute(
      kycWithAdditionalAttributes.additional_attributes
    );
  },
  clearKycWithAdditionalAttributes(state) {
    state.kycWithAdditionalAttributes.basicAttributes = {};
    state.kycWithAdditionalAttributes.derivedAttributes = {};
  },
  assessmentPriorityLoading(state, value) {
    state.assessmentPriorityLoading = value;
  },
  setDoNotMarketLoading(state, value) {
    state.doNotMarketLoading = value;
  },
  setDisableLisaCallsLoading(state, value) {
    state.disableLisaCallsLoading = value;
  },
  setFetchingLoan(state) {
    state.fetchingLoan = true;
  },
  resetFetchingLoan(state) {
    state.fetchingLoan = false;
  },
  setLoanDetails(state, loan) {
    state.loan = loan;
  },
  setLoanMetaAttributesForAssessment(state, loan) {
    state.loanMetaAttributesForAssessment = loan;
  },
  updateKycAdditionalAttributes(state, data) {
    let attributeItemIndex = state.kycWithAdditionalAttributes.basicAttributes.additional_attributes.findIndex(
      item => item.attr === data.question
    );
    if (attributeItemIndex > -1) {
      state.kycWithAdditionalAttributes.basicAttributes.additional_attributes[
        attributeItemIndex
      ].value = data.answer;
    } else {
      state.kycWithAdditionalAttributes.basicAttributes.additional_attributes.push(
        {
          application_id: data.application_id,
          attr: data.question,
          user_id: 0,
          value: data.answer
        }
      );
    }
  },
  setBlacklisted(state, blacklisted) {
    state.blackListed = blacklisted;
  }
};

const actions = {
  getKycAttributesForLoanAssessment: async ({ dispatch, state, commit }) => {
    commit("setFetchingKYC");
    axiosWrapperService
      .axiosGet(
        "/applications/" + state.applicationId + "/getKycAttributesOfLoan"
      )
      .then(response => {
        commit(
          "customerDetails/setCustomerDeleted",
          !!response.data.data?.is_anonymous,
          {
            root: true
          }
        );
        commit("clearAndPushToKycWithAdditionalAttributes", response.data.data);
        commit(
          "loanAssessmentInfos/clearAndPushToKycWithAdditionalAttributes",
          response.data.data,
          { root: true }
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("resetFetchingKYC");
      });
  },
  updateKycAttributeForLoanAssessment: async (
    { dispatch, state, commit },
    payload
  ) => {
    commit("setFetchingLoan");
    axiosWrapperService
      .axiosPost(
        "/applications/" + state.applicationId + "/updateKycAttributesOfLoan",
        payload
      )
      .then(response => {
        //TODO dispatch this one only if want to update hem after attribute update
        // dispatch(
        //   "loanCalculations/refreshHemCalculations",
        //   {
        //     application_id: state.applicationId
        //   },
        //   {
        //     root: true
        //   }
        // );
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: `Attribute value updated!`, type: "success" },
          { root: true }
        );
        dispatch("getKycAttributesForLoanAssessment");
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("resetFetchingLoan");
      });
  },

  getCustomerMetaFlagsForLoan: async ({ dispatch, state, commit }) => {
    commit("setFetchingCustomerMetaFlags");
    commit("resetCustomerMetaFlags");
    axiosWrapperService
      .axiosGet(
        "/applications/" + state.applicationId + "/getCustomerMetaFlagsForLoan"
      )
      .then(response => {
        commit("setCustomerMetaFlags", response.data.data);
      })
      .catch(error => {
        commit("resetCustomerMetaFlags");
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("resetFetchingCustomerMetaFlags");
      });
  },

  getLoanDetails: async ({ dispatch, state, commit }) => {
    commit("setFetchingLoan");
    axiosWrapperService
      .axiosGet("/getLoanApplications/" + state.applicationId)
      .then(response => {
        commit("setLoanDetails", response.data.data);
        dispatch(
          "loanQuality/sortAndSetApplicationQualityByScore",
          {
            score: response.data.data?.model_assessment_quality,
            section: "application"
          },
          { root: true }
        );
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("resetFetchingLoan");
      });
  },
  getLoanMetaAttributesForAssessment: async ({ dispatch, state, commit }) => {
    commit("setFetchingLoan");
    axiosWrapperService
      .axiosGet("/getLoanMetaAttributesForAssessment/" + state.applicationId)
      .then(response => {
        commit("setLoanMetaAttributesForAssessment", response.data.data);
      })
      .catch(error => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("resetFetchingLoan");
      });
  },

  toggleOnAssessmentPriority: async ({ dispatch, state, commit }) => {
    axiosWrapperService
      .axiosPatch(
        `/applications/${state.applicationId}/toggleOnAssessmentPriority`
      )
      .then(() => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: "Successful!", type: "success" },
          { root: true }
        );
      })
      .catch(error => {
        dispatch("getKycAttributesForLoanAssessment");
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(_ => {
        commit("assessmentPriorityLoading", false);
      });
  },

  toggleDoNotMarket: async ({ dispatch, state, commit }) => {
    const customerId =
      state.kycWithAdditionalAttributes?.basicAttributes.user_id;

    axiosWrapperService
      .axiosPatch(`/customers/${customerId}/toggleDoNotMarket`)
      .then(() => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: "Successful!", type: "success" },
          { root: true }
        );
      })
      .catch(error => {
        dispatch("getKycAttributesForLoanAssessment");
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(_ => {
        commit("setDoNotMarketLoading", false);
      });
  },
  toggleDisableLisaCalls: async ({ dispatch, state, commit }) => {
    const customerId =
      state.kycWithAdditionalAttributes?.basicAttributes.user_id;

    axiosWrapperService
      .axiosPatch(`/customers/${customerId}/toggleDisableCuriousCalls`)
      .then(() => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: "Successful!", type: "success" },
          { root: true }
        );
      })
      .catch(error => {
        dispatch("getKycAttributesForLoanAssessment");
        dispatch(
          "globalToastMessage/showSimpleToast",
          { message: formatErrorMessage(error), type: "error" },
          { root: true }
        );
      })
      .finally(_ => {
        commit("setDisableLisaCallsLoading", false);
      });
  },
  updateDiscretionaryQuestionAttributes: async (
    { dispatch, commit },
    postData
  ) => {
    axiosWrapperService
      .axiosPost(
        "/gateway-proxies/fundo-core/loan-application/update-personal-loan-attributes",
        postData
      )
      .then(() => {
        commit("updateKycAdditionalAttributes", postData);
        commit(
          "loanAssessmentInfos/setLoanAssessmentDetailAlert",
          "Successfully Updated.",
          { root: true }
        );
        dispatch(
          "loanAssessmentInfos/getAggregatorLendingDetailsForLoanAssessment",
          { showLoader: true },
          { root: true }
        );
        dispatch(
          "loanAssessmentInfos/getAggregatorPersonalLoansForLoanAssessment",
          { showLoader: true },
          { root: true }
        );
        dispatch(
          "loanAssessmentInfos/getAggregatorIncomeExpensesDetailsForLoanAssessment",
          { showLoader: true },
          { root: true }
        );
      })
      .catch(_ => {
        dispatch(
          "globalToastMessage/showSimpleToast",
          {
            message: "Something went wrong while updating data.",
            type: "error"
          },
          { root: true }
        );
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
