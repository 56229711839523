import { useStore } from "@/store";
import { computed } from "vue";
import { helper as $h } from "@/utils/helper";

export const customerAndLoanDetails = (props, load = true) => {
  const userAttrsToOmitOnMeta = [
    "Reason_1_for_Loan",
    "Sub_Reason_1_for_Loan",
    "Are_you_willing_to_lower_recreational_spending_o",
    "Do_you_foresee_your_financial_situation_changing_o",
    "Do_you_have_any_loans_rental_any_other_finance_o",
    "partner_who_shares_expenses",
    "Do_you_foresee_your_financial_situation_changing_o"
  ];
  const store = useStore();

  const fetchingCustomerMetaFlags = computed(
    () => store.state.customerAndLoanDetails.fetchingCustomerMetaFlags
  );
  const customerMetaFlags = computed(
    () => store.state.customerAndLoanDetails.customerMetaFlags
  );

  const fetchingKYC = computed(
    () => store.state.customerAndLoanDetails.fetchingKYC
  );
  const kycWithAdditionalAttributes = computed(
    () => store.state.customerAndLoanDetails.kycWithAdditionalAttributes
  );
  const loanMetaAttributesForAssessment = computed(
    () => store.state.customerAndLoanDetails.loanMetaAttributesForAssessment
  );

  const fetchingLoan = computed(
    () => store.state.customerAndLoanDetails.fetchingLoan
  );

  const adminUser = computed(() => store.state.auth.loggedUser);

  const loan = computed(() => store.state.customerAndLoanDetails.loan);

  const automatedDecisions = computed(
    () => store.state.lnSysAutoDecisions.automatedDecisions
  );

  const fetchingLnSysAutoDecisions = computed(
    () => store.state.lnSysAutoDecisions.busy
  );

  function checkMatchingLastChars(number, source, stringToMatch) {
    if (source.slice(-number).toLowerCase() === stringToMatch) {
      return source.slice(0, -number) + "";
    }

    return source;
  }

  function loanMetaAttrValueTransformer(metaAttr) {
    const yesOrNoAttr = [
      "Dependent",
      "dependents_cover_their_own_expenses",
      "external_income"
    ];

    const jsonAttr = [
      "loans_ending_next_week",
      "payslip_reason",
      "external_income_reason",
      "Reason_for_wage_change"
    ];

    if (yesOrNoAttr.includes(metaAttr.attr)) {
      if (metaAttr.value > 0) {
        return "yes";
      } else {
        return "No";
      }
    }

    if (metaAttr.attr === "payslip_reason") {
      if ($h.isJsonString(metaAttr.value)) {
        metaAttr.value = JSON.stringify(
          JSON.parse(metaAttr.value).map(item => {
            return item.reason ?? item;
          })
        );
      } else {
        metaAttr.value = JSON.stringify([metaAttr.value]);
      }
    }

    if (jsonAttr.includes(metaAttr.attr)) {
      try {
        return JSON.parse(metaAttr.value).join(", ") || "N/A";
      } catch (error) {
        return metaAttr.value;
      }
    }

    return metaAttr.value;
  }

  const enabledAgreggatorDataField = computed(
    () => store.state.loanAssessmentInfos.enabledAgreggatorDataField
  );

  function getKycAttributeValueByName(name) {
    let attrValue = "";
    if (
      kycWithAdditionalAttributes.value.basicAttributes
        .additional_attributes !== undefined
    ) {
      kycWithAdditionalAttributes.value.basicAttributes.additional_attributes.forEach(
        function(attrItem) {
          if (name === attrItem.attr) {
            attrValue = attrItem.value;
          }
        }
      );
    }
    return attrValue === "" ? "-" : attrValue;
  }

  function onClickTransformToInput(dataFieldName) {
    if (enabledAgreggatorDataField.value === "") {
      store.commit(
        "loanAssessmentInfos/setEnabledAgreggatorDataField",
        dataFieldName
      );
    }

    if (
      enabledAgreggatorDataField.value !== "" &&
      enabledAgreggatorDataField.value !== dataFieldName
    ) {
      store.commit(
        "loanAssessmentInfos/setLoanAssessmentDetailAlert",
        "There is already an active input field.Please Either complete or dismiss active input field"
      );
    }
  }

  function updateAttributes(type, value) {
    store.dispatch(
      "customerAndLoanDetails/updateKycAttributeForLoanAssessment",
      {
        type,
        value
      }
    );
  }

  function onCloseKycSelectBox() {
    store.commit("loanAssessmentInfos/setEnabledAgreggatorDataField", "");
  }

  function onCloseAndSubmitQAattrs(answer, question, appId) {
    if (getKycAttributeValueByName(question) === answer.toLowerCase()) {
      store.dispatch(
        "globalToastMessage/showSimpleToast",
        { message: "Select different option or cancle.", type: "error" },
        { root: true }
      );
      return;
    }
    store.dispatch(
      "customerAndLoanDetails/updateDiscretionaryQuestionAttributes",
      {
        question: question,
        answer: answer.toLowerCase(),
        application_id: appId,
        admin_id: adminUser.value.id
      }
    );
    onCloseKycSelectBox();
  }

  function reInit() {
    store.commit("customerAndLoanDetails/clearKycWithAdditionalAttributes");
  }

  reInit();

  init();

  function init() {
    if (load) {
      store.commit(
        "customerAndLoanDetails/setApplicationId",
        props.applicationId
      );
      store.dispatch(
        "customerAndLoanDetails/getKycAttributesForLoanAssessment"
      );
      store.dispatch(
        "customerAndLoanDetails/getLoanMetaAttributesForAssessment"
      );
      store.dispatch(
        "lnSysAutoDecisions/getAutomatedDecisionsOfLoan",
        props.applicationId
      );
      if (props.showLoanInfo) {
        store.dispatch("loanQuality/resetQualityAndScore", { root: true });
        store.dispatch("customerAndLoanDetails/getLoanDetails");
      }
    }
  }

  function toggleOnAssessmentPriority() {
    store.commit("customerAndLoanDetails/assessmentPriorityLoading", true);
    store.dispatch("customerAndLoanDetails/toggleOnAssessmentPriority");
  }

  function toggleDoNotMarket() {
    store.commit("customerAndLoanDetails/setDoNotMarketLoading", true);
    store.dispatch("customerAndLoanDetails/toggleDoNotMarket");
  }

  function toggleDisableLisaCalls() {
    store.commit("customerAndLoanDetails/setDisableLisaCallsLoading", true);
    store.dispatch("customerAndLoanDetails/toggleDisableLisaCalls");
  }

  return {
    fetchingCustomerMetaFlags,
    customerMetaFlags,
    fetchingKYC,
    checkMatchingLastChars,
    userAttrsToOmitOnMeta,
    kycWithAdditionalAttributes,
    loanMetaAttributesForAssessment,
    getKycAttributeValueByName,
    loanMetaAttrValueTransformer,
    fetchingLoan,
    loan,
    fetchingLnSysAutoDecisions,
    automatedDecisions,
    toggleOnAssessmentPriority,
    toggleDoNotMarket,
    toggleDisableLisaCalls,
    onClickTransformToInput,
    onCloseKycSelectBox,
    onCloseAndSubmitQAattrs,
    updateAttributes
  };
};
